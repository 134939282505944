import { S3Client, GetObjectCommand, PutObjectCommand } from '@aws-sdk/client-s3'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import { Auth } from 'aws-amplify'

/**
 * This function returns the S3 Pre-Signed URL to get the object.
 *
 * @param {string} keyPrefix - The profile image name from the database
 * @return {string} The pre-signed URL
 *
 * @example
 *
 *     getImageUrl('/media/avatars/tn/longcognitoid/test.jpeg')
 */
const getImageUrl = async (keyPrefix) => {
  try {
    if (keyPrefix) {
      let creds = await Auth.currentCredentials()
      creds = Auth.essentialCredentials(creds)
      const s3Configuration = {
        credentials: creds,
        region: process.env.VUE_APP_AMP_region
      }
      const s3 = new S3Client(s3Configuration)
      const getParams = new GetObjectCommand({
        Bucket: process.env.VUE_APP_media_bucket_name,
        Key: keyPrefix
      })
      let url = await getSignedUrl(s3, getParams, { expiresIn: 60 * 60 })
      url = url.replace(process.env.VUE_APP_media_bucket_url, process.env.VUE_APP_media_cdn_url)
      return Promise.resolve(url)
    } else {
      return Promise.resolve('')
    }
  } catch (error) {
    return Promise.resolve('')
  }
}

/**
 * This function uploads a file to S3
 *
 * @param {string} file - The file
 * @param {string} type - The file type - avatars or clubs
 * @return {string} The full file name of the uploaded object
 *
 * @example
 *
 *     putImage('anImage.jpeg', 'avatars')
 */

const putImage = async (file, type) => {
  try {
    const user = await Auth.currentCredentials()
    const lastDot = file.name.lastIndexOf('.')
    const ext = file.name.substring(lastDot + 1)
    const objectKey = `media/${type}/fs/${user.identityId}/${Date.now()}.${ext}`

    let creds = await Auth.currentCredentials()
    creds = Auth.essentialCredentials(creds)
    const s3Configuration = {
      credentials: creds,
      region: process.env.VUE_APP_AMP_region
    }
    const s3 = new S3Client(s3Configuration)
    const putParams = new PutObjectCommand({
      Bucket: process.env.VUE_APP_media_bucket_name,
      Key: objectKey,
      ServerSideEncryption: 'AES256',
      Body: file,
      Metadata: {
        cognitoOwner: user.identityId
      }
    })
    const putObject = await s3.send(putParams)
    if (putObject.$metadata.httpStatusCode === 200) {
      return objectKey
    } else {
      throw new Error('Upload failed')
    }
  } catch (error) {
    throw new Error(error)
  }
}

export {
  getImageUrl,
  putImage
}
