<template>
  <ion-avatar>
    <div v-if="value === null || value === undefined" class="blank-logo" v-text="initials"></div>
    <img v-else alt="" :src="src">
  </ion-avatar>
</template>

<script>
import {getImageUrl} from "../utils/imageHandler";

export default {
  name: "Avatar",
  props: {
    value: {
      type: String
    },
    initials: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      src: null
    }
  },
  async created() {
    this.src = await getImageUrl(this.value)
  },
  watch: {
    async value(value) {
      this.src = await getImageUrl(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.blank-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #c3c3c3;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}
</style>